import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout/Layout';

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <section className="markdown py-14 lg:pb-30 lg:pt-0">
        <div className="container mx-auto xs:px-20 sm:px-42 md:px-42 lg:px-60">
          <h3 className="text-2xl lg:text-4xl font-semibold">Blog da Dedalog</h3>
          {data.allMdx.nodes.map((node) => (
            <article key={node.id}>
              <h3>
                <Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link>
              </h3>
              <span className="block py-1">Publicado em: {node.frontmatter.date}</span>
              <span className="block text-gray-400">Tags: {node.frontmatter.tags.join(', ')}</span>
            </article>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          title
          tags
        }
        id
        slug
      }
    }
  }
`;

export default BlogPage;
